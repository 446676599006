import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";
import CircleLoader from "react-spinners/PulseLoader";


const BeatSpinner = ({loading}) => {
    return (
        <div className = 'd-flex align-items-center justify-content-center' style = {{minHeight: '100vh'}}>
            <BeatLoader size={15} color={"#2c7be5"} loading={loading}/>
        </div>
    )
}


export const BeatSpinner1 = ({loading}) => {
    return (
        <div className = 'd-flex align-items-center justify-content-center' style = {{minHeight: '100%'}}>
            <CircleLoader size={14} color={"#2c7be5"} loading={loading}/>
        </div>
    )
}


export const BeatSpinner2 = ({loading}) => {
    return (
        <div className = 'd-flex align-items-center justify-content-center' style = {{height: '50vh'}}>
            <BeatLoader size={15} color={"#2c7be5"} loading={loading}/>
        </div>
    )
}



export default BeatSpinner;